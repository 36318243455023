import React, { useState } from 'react'
import { DndContext } from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { Modal, Input, Table } from 'antd'
import { Button } from '../../components'
import { CSS } from '@dnd-kit/utilities'
const { TextArea } = Input

const columns = [
    {
        title: 'Değer',
        dataIndex: 'content'
    }
]
const Row = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({
        id: props['data-row-key']
    })
    const style = {
        ...props.style,
        transform: CSS.Transform.toString(
            transform && {
                ...transform,
                scaleY: 1
            }
        ),
        transition,
        cursor: 'move',
        ...(isDragging
            ? {
                  position: 'relative',
                  zIndex: 9999
              }
            : {})
    }
    return (
        <tr
            {...props}
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
        />
    )
}

const FoodAddRecipe = ({ recipes, setRecipes }) => {
    const [content, setContent] = useState('')
    const [modal, setModal] = useState(false)

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setRecipes((prev) => {
                const activeIndex = prev.findIndex((i) => i.key === active.id)
                const overIndex = prev.findIndex((i) => i.key === over?.id)
                return arrayMove(prev, activeIndex, overIndex)
            })
        }
    }

    const handleAddMaterial = () => {
        setRecipes([
            ...recipes,
            {
                content,
                key: recipes.length
            }
        ])
        setContent('')
        setModal(false)
    }

    return (
        <>
            <div className="mt-4">
                <div className="text-xl font-semibold text-start">Tarif*</div>
                {recipes.length > 0 && (
                    <DndContext onDragEnd={onDragEnd}>
                        <SortableContext
                            items={recipes.map((i) => i.key)}
                            strategy={verticalListSortingStrategy}
                        >
                            <Table
                                components={{
                                    body: {
                                        row: Row
                                    }
                                }}
                                rowKey="key"
                                columns={columns}
                                dataSource={recipes}
                                pagination={false}
                            />
                        </SortableContext>
                    </DndContext>
                )}

                <Button
                    className="mt-4 bg-blue-500"
                    onClick={() => setModal(true)}
                >
                    Tarif Adımı Ekle
                </Button>
            </div>
            <Modal
                title="Tarif Adımı Ekle"
                centered
                open={modal}
                onCancel={() => setModal(false)}
                footer={[
                    <Button key="cancel" onClick={() => setModal(false)}>
                        İptal
                    </Button>,
                    <Button
                        key="link"
                        type="primary"
                        onClick={handleAddMaterial}
                    >
                        Kaydet
                    </Button>
                ]}
            >
                <div className="space-y-4">
                    <TextArea
                        showCount
                        maxLength={200}
                        style={{ height: 120, marginBottom: 24 }}
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        placeholder="Tarif adımı girin"
                    />
                </div>
            </Modal>
        </>
    )
}

export default FoodAddRecipe
