import React, { useEffect, useState } from 'react'
import { Image, Tooltip } from 'antd'
import label from '../../constant/label'
import { useParams } from 'react-router-dom'
import { getDeviceDetail } from '../../helper/api'
import { toast } from 'react-toastify'
import { Loading } from '../../components'

const DeviceDetail = () => {
    const { id } = useParams()

    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)

    const getDetail = async () => {
        setLoading(true)
        const { status, result, message } = await getDeviceDetail(id)
        if (status) {
            setData(result)
        } else {
            toast(message, {
                type: 'error'
            })
        }
        setLoading(false)
    }

    useEffect(() => {
        getDetail()
    }, [])

    if (loading && !data) return <Loading />

    return (
        <div>
            {data.user && (
                <div className="flex items-center justify-center">
                    <Tooltip title={label[data.user.country]}>
                        <Image
                            preview={false}
                            width={34}
                            src={require('../../assets/flag/' +
                                data.user.country +
                                '.png')}
                        />
                    </Tooltip>
                    <span className="ml-2 text-gray-600 text-xl">
                        {data.user.username}
                    </span>
                </div>
            )}

            <div>Oluşturulma Tarihi: {data.created_at}</div>
            <div>Güncellenme Tarihi: {data.updated_at}</div>

            <div>Giriş Yapıldı: {data.login ? 'Aktif' : 'Pasif'}</div>
            <div>One Signal Player Id: {data.one_signal_player_id}</div>
            <div>Marka: {data.brand}</div>
            <div>Versiyon: {data.build_number}</div>
            <div>Cihaz Id: {data.device_id}</div>
            <div>Cihaz Tipi: {data.device_type}</div>
            <div>Oturum Sayısı: {data.device_type}</div>
            <div>Sistem Adı: {data.system_name}</div>
            <div>Benzersiz Id: {data.unique_id}</div>
        </div>
    )
}

export default DeviceDetail
