import React from 'react';
import {Button} from "./index";
import {Input} from "antd";

const TableHeader = ({pageName, buttonTitle, onClick, loading }) => {
    return (
        <div className="flex flex-col mb-5">
            <h1>{pageName}</h1>
            <p>{pageName}</p>
            <div className="flex flex-col sm:flex-row">
                <Input placeholder="Basic usage" />
                <div>
                    {
                        buttonTitle &&
                        <Button title={buttonTitle} handleChange={onClick} loading={loading} />
                    }
                </div>
            </div>
        </div>
    );
};

export default TableHeader;
