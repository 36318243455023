import React from 'react'
import { Select as AntdSelect } from 'antd'

const Select = ({ label, ...props }) => {
    const onSelect = (value) => {
        if (props.mode === 'multiple') {
            props.setValue([
                ...props.value,
                props.options.find((_) => _.value === value)
            ])
        } else {
            props.setValue(props.options.find((_) => _.value === value))
        }
    }

    const onDeselect = (value) => {
        if (props.mode === 'multiple') {
            const index = props.value.findIndex((_) => _.value === value)

            if (index !== -1) {
                const newList = [...props.value]
                newList.splice(index, 1)
                props.setValue(newList)
            }
        } else {
            props.setValue(null)
        }
    }

    return (
        <div className="flex flex-col items-start">
            {label && <span className="mb-1 font-semibold">{label}</span>}
            <AntdSelect
                allowClear
                showSearch
                style={{
                    width: '100%',
                    textAlign: 'start'
                }}
                size={'large'}
                filterOption={(inputValue, option) =>
                    option.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                }
                onSelect={onSelect}
                onDeselect={onDeselect}
                {...props}
            />
        </div>
    )
}

export default Select
