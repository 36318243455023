export default {
    ad: 'Andorra',
    ae: 'Birleşik Arap Emirlikleri',
    af: 'Afganistan',
    ag: 'Antigua-Barbuda',
    ai: 'Anguilla',
    al: 'Arnavutluk',
    am: 'Ermenistan',
    ao: 'Angola',
    aq: 'Antarktika',
    ar: 'Arjantin',
    at: 'Avusturya',
    au: 'Avustralya',
    aw: 'Aruba',
    ax: 'Aland Adaları',
    az: 'Azerbeycan',
    ba: 'Bosna Hersek',
    bb: 'Barbados',
    bd: 'Bangladeş',
    be: 'Belçika',
    bf: 'Burkina Faso',
    bg: 'Bulgaristan',
    bh: 'Bahreyn',
    bi: 'Burundi',
    bj: 'Benin',
    bm: 'Bermuda',
    bn: 'Brunei',
    bo: 'Bolivya',
    br: 'Brezilya',
    bs: 'Bahamalar',
    bt: 'Butan',
    bw: 'Botsvana',
    by: 'Belarus',
    bz: 'Belize',
    ca: 'Kanada',
    cc: 'Cocos Adaları',
    cd: 'Kongo Demokratik Cumhuriyeti',
    cf: 'Orta Afrika Cumhuriyeti',
    cg: 'Kongo',
    ch: 'İsviçre',
    ci: 'Fildişi Sahili',
    ck: 'Cook Adaları',
    cl: 'Şili',
    cm: 'Kamerun',
    cn: 'Çin Halk Cumhuriyeti',
    co: 'Kolombiya',
    cr: 'Kosta Rika',
    ct: 'Katalonya',
    cu: 'Küba',
    cv: 'Cape Verde',
    cw: 'Curaçao',
    cx: 'Christmas Adası',
    cy: 'Kıbrıs Rum Kesimi',
    cz: 'Çek Cumhuriyeti',
    de: 'Almanya',
    dj: 'Cibuti',
    dk: 'Danimarka',
    dm: 'Dominika',
    do: 'Dominik Cumhuriyeti',
    dz: 'Cezayir',
    ec: 'Ekvator',
    ee: 'Estonya',
    eg: 'Mısır',
    eh: 'Batı Sahra',
    er: 'Eritre',
    es: 'İspanya',
    et: 'Etiyopya',
    fi: 'Finlandiya',
    fk: 'Falkland Adaları',
    fm: 'Mikronezya',
    fo: 'Faroe Adaları',
    fr: 'Fransa',
    ga: 'Gabon',
    gb: 'İngiltere',
    gd: 'Grenada',
    ge: 'Gürcistan',
    gg: 'Guernsey',
    gh: 'Gana',
    gl: 'Grönland',
    gm: 'Gambiya',
    gn: 'Gine',
    gq: 'Ekvator Ginesi',
    gr: 'Yunanistan',
    gs: 'Güney Georgia ve Güney Sandwich Adaları',
    gt: 'Guatemala',
    gu: 'Guam',
    gw: 'Gine-Bissau',
    gy: 'Guyana',
    hk: 'Hong-Kong',
    hr: 'Hırvatistan',
    ht: 'Haiti',
    hu: 'Macaristan',
    id: 'Endonezya',
    ie: 'İrlanda',
    im: 'Man Adası',
    in: 'Hindistan',
    io: 'İngiliz Hint Okyanusu Toprakları',
    iq: 'Irak',
    ir: 'İran',
    is: 'İzlanda',
    it: 'İtalya',
    je: 'Jersey',
    jo: 'Ürdün',
    jp: 'Japonya',
    ke: 'Kenya',
    kg: 'Kırgızistan',
    kh: 'Kamboçya',
    ki: 'Kiribati',
    km: 'Komorlar',
    kn: 'Saint Kitts ve Nevis',
    kp: 'Kuzey Kore',
    kr: 'Güney Kore Cumhuriyeti',
    kw: 'Kuveyt',
    ky: 'Cayman Adaları',
    kz: 'Kazakistan',
    la: 'Laos Demokratik Halk Cumhuriyeti',
    lb: 'Lübnan',
    lc: 'Saint Lucia',
    li: 'Liechtenstein',
    lk: 'Sri Lanka',
    lr: 'Liberya',
    lt: 'Litvanya',
    lu: 'Lüksemburg',
    lv: 'Letonya',
    ly: 'Libya',
    mc: 'Monako',
    md: 'Moldovya',
    me: 'Karadağ',
    mg: 'Madagaskar',
    mh: 'Marshal Adaları',
    mk: 'Makedonya',
    mm: 'Burma',
    mn: 'Moğolistan',
    mo: 'Makao',
    mp: 'Kuzey Mariana Adaları',
    mr: 'Moritanya',
    ms: 'Montserrat',
    mt: 'Malta',
    mu: 'Mauritius',
    mv: 'Maldivler',
    mw: 'Malavi',
    mx: 'Meksika',
    my: 'Malezya',
    mz: 'Mozambik',
    na: 'Namibya',
    ne: 'Nijer',
    nf: 'Norfolk Adası',
    ng: 'Nijerya',
    ni: 'Nikaragua',
    nl: 'Hollanda',
    no: 'Norveç',
    np: 'Nepal',
    nr: 'Nauru',
    nu: 'Niue Adası',
    nz: 'Yeni Zelanda',
    om: 'Umman',
    pa: 'Panama',
    pe: 'Peru',
    pf: 'Fransız Polinezyası',
    pg: 'Papua',
    ph: 'Filipinler',
    pk: 'Pakistan',
    pl: 'Polonya',
    pn: 'Pitcairn',
    pr: 'Porto Riko',
    pt: 'Portekiz',
    py: 'Paraguay',
    qa: 'Katar',
    ro: 'Romanya',
    rs: 'Sırbistan',
    ru: 'Rusya Federasyonu',
    rw: 'Ruanda',
    sa: 'Suudi Arabistan',
    sb: 'Solomon Adaları',
    sc: 'Seyşeller',
    sd: 'Sudan',
    se: 'İsveç',
    sg: 'Singapur',
    si: 'Slovenya',
    sk: 'Slovak Cumhuriyeti',
    sl: 'Sierra Leone',
    sm: 'San Marino',
    sn: 'Senegal',
    so: 'Somali',
    sr: 'Surinam',
    ss: 'Güney Sudan',
    st: 'Sao Tome ve Principe',
    sv: 'El Salvador',
    sy: 'Suriye Arap Cumhuriyeti',
    sz: 'Svaziland',
    tc: 'Turks ve Caicos Adaları',
    td: 'Çad',
    tf: 'Fransa Güney Bölgeleri',
    tg: 'Togo',
    th: 'Tayland',
    tj: 'Tacikistan',
    tk: 'Tokelau',
    tl: 'Batı Timor',
    tm: 'Türkmenistan',
    tn: 'Tunus',
    to: 'Tonga',
    tr: 'Türkiye Cumhuriyeti',
    tt: 'Trinidad ve Tobago',
    tv: 'Tuvalu',
    tz: 'Tanzanya',
    ua: 'Ukrayna',
    ug: 'Uganda',
    us: 'Amerika Birleşik Devletleri',
    uy: 'Uruguay',
    uz: 'Özbekistan',
    va: 'Vatikan',
    vc: 'Saint Vincent ve Grenadinler',
    ve: 'Venezuela',
    vg: 'İngiliz Virgin Adaları',
    vi: 'Amerikan Virgin Adaları',
    vn: 'Vietnam',
    vu: 'Vanuatu',
    ws: 'Samoa',
    ye: 'Yemen',
    za: 'Güney Afrika Cumhuriyeti',
    zm: 'Zambiya',
    zw: 'Zimbabve'
}
