import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Input, LanguageInput, Loading, TableHeader } from "../../components";
import { toast, ToastContainer } from "react-toastify";
import { editAdminUnit, getUnitDetail } from "../../helper/api";

const UserEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { item } = state;
    const [language, setLanguage] = useState({
        tr: "",
        en: "",
        fr: "",
        ar: "",
        it: "",
        ru: "",
        hi: "",
        pt: "",
        de: "",
        el: "",
        pl: "",
        zh: "",
    });
    const [loading, setLoading] = useState(true);

    const getDetail = async () => {
        setLoading(true);
        const { status, result } = await getUnitDetail(id);
        setLoading(false);
        if (status) {
            setLanguage({
                tr: result.tr,
                en: result.en,
                fr: result.fr,
                ar: result.ar,
                it: result.it,
                ru: result.ru,
                hi: result.hi,
                pt: result.pt,
                de: result.de,
                el: result.el,
                pl: result.pl,
                zh: result.zh,
            });
        }
    };

    const editCategory = async () => {
        const credentials = {
            name: language.tr,
            tr: language.tr,
            en: language.en,
            fr: language.fr,
            ar: language.ar,
            it: language.it,
            ru: language.ru,
            hi: language.hi,
            pt: language.pt,
            de: language.de,
            el: language.el,
            pl: language.pl,
            zh: language.zh,
        };
        const { status } = await editAdminUnit(item.id, credentials);
        if (status) {
            navigate(-1);
        } else {
            toast.error("Lütfen tekrar deneyiniz!");
        }
    };

    useEffect(() => {
        getDetail();
    }, []);

    return (
        <div>
            <TableHeader
                pageName={"Birim Düzenle"}
                buttonTitle={"Değişiklikleri Kaydet"}
                onClick={editCategory}
            />
            {loading ? (
                <Loading />
            ) : (
                <div className="mt-10">
                    heyys
                    <ToastContainer position="bottom-right" newestOnTop />
                </div>
            )}
        </div>
    );
};

export default UserEdit;
