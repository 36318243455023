export const isBoolean = (variable) => 'boolean' === typeof variable

export const uuid = () => {
    return Date.now().toString(36) + Math.random().toString(36).substring(2)
}

export const arrayMove = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
        let k = new_index - arr.length + 1
        while (k--) {
            arr.push(undefined)
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
    return arr
}

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
}

export const validateApplePrivateMail = (email) => {
    const regex = /@.*?(appleid|privaterelay)/i
    return !regex.test(email)
}

export const youTubeUrlToId = (url) => {
    const p =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
    if (url.match(p)) {
        return url.match(p)[1]
    }
    return false
}

export const formatDate = (date) =>
    ('0' + date.getDate()).slice(-2) +
    '.' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '.' +
    date.getFullYear()

export const specialCharacters = [
    '\\',
    '/',
    '+',
    '*',
    '?',
    '[',
    '^',
    ']',
    '$',
    '(',
    ')',
    '{',
    '}',
    '=',
    '!',
    '<',
    '>',
    '&',
    '@',
    '#',
    '%',
    '~',
    '|',
    ':',
    ';',
    '-',
    "'",
    "'",
    '‘',
    '’',
    '"',
    '“',
    '”',
    ',',
    '₺',
    '€',
    '£',
    '•'
]

export const numberRange = (start, end, cycle) => {
    if (cycle) {
        const list = []
        for (let i = start; i <= end; i += cycle) {
            list.push(i)
        }
        return list
    }
    return new Array(end - start).fill().map((d, i) => i + start)
}
