import React, { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Modal, Upload } from 'antd'
import { getBase64 } from '../helper/image'
import ImgCrop from 'antd-img-crop'

const UploadImage = ({
    imageList,
    setImageList,
    setChangeImage,
    maxCount = 1,
    isCrop = false,
    ...props
}) => {
    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')

    const handleCancel = () => setPreviewOpen(false)

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        setPreviewImage(file.url || file.preview)
        setPreviewOpen(true)
        setPreviewTitle(
            file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        )
    }

    const beforeUpload = async (file) => {
        const b64 = await getBase64(file)
        setImageList([
            ...imageList,
            { ...file, originFileObj: file, base64: b64, key: file.uid }
        ])
        if (setChangeImage) {
            setChangeImage(true)
        }
        return false
    }

    const onRemove = async (file) => {
        const index = imageList.findIndex((_) => _.uid === file.uid)

        if (index !== -1) {
            const newList = [...imageList]
            newList.splice(index, 1)
            setImageList(newList)
        }
    }

    const uploadButton = (
        <div style={{ width: 400 }}>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8
                }}
            >
                Resim Seç
            </div>
        </div>
    )

    return (
        <>
            {isCrop ? (
                <ImgCrop rotationSlider modalTitle={'Resmi Kes'}>
                    <Upload
                        listType="picture-card"
                        fileList={imageList}
                        onPreview={handlePreview}
                        beforeUpload={beforeUpload}
                        onRemove={onRemove}
                        {...props}
                    >
                        {imageList.length >= maxCount ? null : uploadButton}
                    </Upload>
                </ImgCrop>
            ) : (
                <Upload
                    listType="picture-card"
                    fileList={imageList}
                    onPreview={handlePreview}
                    beforeUpload={beforeUpload}
                    onRemove={onRemove}
                    {...props}
                >
                    {imageList.length >= maxCount ? null : uploadButton}
                </Upload>
            )}

            <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
            >
                <img
                    alt="example"
                    style={{
                        width: '100%'
                    }}
                    src={previewImage}
                />
            </Modal>
        </>
    )
}

export default UploadImage
