import './App.css'
import Routers from './Routers'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { useEffect } from 'react'
import StatusContextProvider from './context/StatusContext'

function App() {
    useEffect(() => {
        document.title = 'Reciples Panel'
    }, [])

    return (
        <>
            <StatusContextProvider>
                <Routers />
            </StatusContextProvider>
            <ToastContainer />
        </>
    )
}

export default App
