import React, { useEffect, useState } from 'react'
import { LanguageInput, UploadImage } from '../../components'
import { editAdminMaterial, getMaterialDetail } from '../../helper/api'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { getBase64 } from '../../helper/image'
import { formatLanguageCode } from '../../helper/language'

const MaterialEdit = () => {
    const { id } = useParams()

    const navigate = useNavigate()

    const [language, setLanguage] = useState({
        de: '',
        el: '',
        en: '',
        es: '',
        fr: '',
        hi: '',
        it: '',
        ja: '',
        ko: '',
        pl: '',
        pt: '',
        ru: '',
        tr: '',
        zh: ''
    })
    const [loading, setLoading] = useState(false)
    const [imageList, setImageList] = useState([])
    const [changeImage, setChangeImage] = useState(false)

    const disabled =
        language.de.trim().length === 0 ||
        language.el.trim().length === 0 ||
        language.en.trim().length === 0 ||
        language.es.trim().length === 0 ||
        language.fr.trim().length === 0 ||
        language.hi.trim().length === 0 ||
        language.it.trim().length === 0 ||
        language.ja.trim().length === 0 ||
        language.ko.trim().length === 0 ||
        language.pl.trim().length === 0 ||
        language.pt.trim().length === 0 ||
        language.ru.trim().length === 0 ||
        language.tr.trim().length === 0 ||
        language.zh.trim().length === 0 ||
        imageList.length === 0

    const editMaterial = async () => {
        setLoading(true)
        let image = null
        if (changeImage) {
            const b64 = await getBase64(imageList[0].originFileObj)
            image = b64.split(',')[1]
        }

        const credentials = {
            name: language.tr.trim(),
            image,
            ...formatLanguageCode(language)
        }
        const { status, message } = await editAdminMaterial(id, credentials)
        if (status) {
            toast('Malzeme başarıyla güncellendi.', {
                type: 'success'
            })
            navigate(-1)
        } else {
            toast(message, {
                type: 'error'
            })
        }
        setLoading(false)
    }

    const getDetail = async () => {
        setLoading(true)
        const { status, result, message } = await getMaterialDetail(id)
        if (status) {
            setLanguage(formatLanguageCode(result))
            setImageList([
                {
                    uid: 1,
                    name: '',
                    status: 'done',
                    url: result.image
                }
            ])
        } else {
            toast(message, {
                type: 'error'
            })
        }
        setLoading(false)
    }

    useEffect(() => {
        getDetail()
    }, [])

    return (
        <div className="">
            <h1 className="font-bold text-3xl text-left mb-5">
                Malzeme Düzenle
            </h1>
            <UploadImage
                imageList={imageList}
                setImageList={setImageList}
                setChangeImage={setChangeImage}
            />
            <LanguageInput
                placeholder={'Malzeme'}
                language={language}
                setLanguage={setLanguage}
                loading={loading}
                setLoading={setLoading}
            />
            <Button
                block
                type="primary"
                icon={<UploadOutlined />}
                size={'large'}
                loading={loading}
                onClick={editMaterial}
                className="mt-4 bg-blue-400"
                disabled={disabled}
            >
                Güncelle
            </Button>
        </div>
    )
}

export default MaterialEdit
