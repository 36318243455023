import React, { useState } from 'react'
import { wordTranslate } from '../helper/api'
import { Button, Input } from 'antd'
import { RedoOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'

const LanguageInput = ({
    placeholder,
    language,
    setLanguage,
    loading,
    setLoading
}) => {
    const handleTranslate = async () => {
        setLoading(true)
        const credentials = { text: language.tr }
        const { status, result, message } = await wordTranslate(credentials)
        if (status === true) {
            setLanguage({
                ...language,
                de: result['de'],
                el: result['el'],
                en: result['en'],
                es: result['es'],
                fr: result['fr'],
                hi: result['hi'],
                it: result['it'],
                ja: result['ja'],
                ko: result['ko'],
                pl: result['pl'],
                pt: result['pt'],
                ru: result['ru'],
                zh: result['zh']
            })
        } else {
            toast(message, {
                type: 'error'
            })
        }
        setLoading(false)
    }

    return (
        <div>
            <div className="flex flex-col sm:flex-row mt-2 gap-2 mb-4">
                <Input
                    value={language.tr}
                    onChange={(e) =>
                        setLanguage({ ...language, tr: e.target.value })
                    }
                    placeholder={placeholder}
                    size={'large'}
                />
                <Button
                    type="default"
                    icon={<RedoOutlined />}
                    size={'large'}
                    onClick={handleTranslate}
                    loading={loading}
                >
                    Çevir
                </Button>
            </div>
            <hr />
            <div className="mt-4">
                <Input
                    placeholder={'Almanca'}
                    suffix="DE"
                    value={language.de}
                    onChange={(e) =>
                        setLanguage({ ...language, de: e.target.value })
                    }
                    size={'large'}
                />
                <Input
                    placeholder={'Yunanca'}
                    suffix="EL"
                    value={language.el}
                    onChange={(e) =>
                        setLanguage({ ...language, el: e.target.value })
                    }
                    size={'large'}
                    className="mt-2"
                />
                <Input
                    placeholder={'İngilizce'}
                    suffix="EN"
                    value={language.en}
                    onChange={(e) =>
                        setLanguage({ ...language, en: e.target.value })
                    }
                    size={'large'}
                    className="mt-2"
                />
                <Input
                    placeholder={'İspanyolca'}
                    suffix="ES"
                    value={language.es}
                    onChange={(e) =>
                        setLanguage({ ...language, es: e.target.value })
                    }
                    size={'large'}
                    className="mt-2"
                />
                <Input
                    placeholder={'Fransızca'}
                    suffix="FR"
                    value={language.fr}
                    onChange={(e) =>
                        setLanguage({ ...language, fr: e.target.value })
                    }
                    size={'large'}
                    className="mt-2"
                />
                <Input
                    placeholder={'Hintce'}
                    suffix="HI"
                    value={language.hi}
                    onChange={(e) =>
                        setLanguage({ ...language, hi: e.target.value })
                    }
                    size={'large'}
                    className="mt-2"
                />
                <Input
                    placeholder={'İtalyanca'}
                    suffix="IT"
                    value={language.it}
                    onChange={(e) =>
                        setLanguage({ ...language, it: e.target.value })
                    }
                    size={'large'}
                    className="mt-2"
                />
                <Input
                    placeholder={'Japonca'}
                    suffix="JA"
                    value={language.ja}
                    onChange={(e) =>
                        setLanguage({ ...language, ja: e.target.value })
                    }
                    size={'large'}
                    className="mt-2"
                />
                <Input
                    placeholder={'Korece'}
                    suffix="KO"
                    value={language.ko}
                    onChange={(e) =>
                        setLanguage({ ...language, ko: e.target.value })
                    }
                    size={'large'}
                    className="mt-2"
                />
                <Input
                    placeholder={'Lehce'}
                    suffix="PL"
                    value={language.pl}
                    onChange={(e) =>
                        setLanguage({ ...language, pl: e.target.value })
                    }
                    size={'large'}
                    className="mt-2"
                />
                <Input
                    placeholder={'Portekizce'}
                    suffix="PT"
                    value={language.pt}
                    onChange={(e) =>
                        setLanguage({ ...language, pt: e.target.value })
                    }
                    size={'large'}
                    className="mt-2"
                />

                <Input
                    placeholder={'Rusca'}
                    suffix="RU"
                    value={language.ru}
                    onChange={(e) =>
                        setLanguage({ ...language, ru: e.target.value })
                    }
                    size={'large'}
                    className="mt-2"
                />
                <Input
                    placeholder={'Çince'}
                    suffix="ZH"
                    value={language.zh}
                    onChange={(e) =>
                        setLanguage({ ...language, zh: e.target.value })
                    }
                    size={'large'}
                    className="mt-2"
                />
            </div>
        </div>
    )
}

export default LanguageInput
