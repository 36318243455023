import React from 'react'
import { DndContext } from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { Table, Image } from 'antd'
import { Input, UploadImage } from '../../components'
import { CSS } from '@dnd-kit/utilities'

const columns = [
    {
        title: 'Resim',
        dataIndex: 'base64',
        render: (_) => <Image width={200} src={_} />
    }
]
const Row = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({
        id: props['data-row-key']
    })
    const style = {
        ...props.style,
        transform: CSS.Transform.toString(
            transform && {
                ...transform,
                scaleY: 1
            }
        ),
        transition,
        cursor: 'move',
        ...(isDragging
            ? {
                  position: 'relative',
                  zIndex: 9999
              }
            : {})
    }
    return (
        <tr
            {...props}
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
        />
    )
}

const FoodAddGallery = ({ images, setImages, videoUrl, setVideoUrl }) => {
    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setImages((prev) => {
                const activeIndex = prev.findIndex((i) => i.key === active.id)
                const overIndex = prev.findIndex((i) => i.key === over?.id)
                return arrayMove(prev, activeIndex, overIndex)
            })
        }
    }

    return (
        <div className="mt-4">
            <div className="text-xl font-semibold text-start">Resimler*</div>
            {images.length > 0 && (
                <>
                    <DndContext onDragEnd={onDragEnd}>
                        <SortableContext
                            items={images.map((i) => i.key)}
                            strategy={verticalListSortingStrategy}
                        >
                            <Table
                                components={{
                                    body: {
                                        row: Row
                                    }
                                }}
                                rowKey="key"
                                columns={columns}
                                dataSource={images}
                                pagination={false}
                            />
                        </SortableContext>
                    </DndContext>
                    <div className="mt-4">
                        İlk sıradaki resim vitrin resmi olur.
                    </div>
                </>
            )}

            <UploadImage
                imageList={images}
                setImageList={setImages}
                showUploadList={false}
                maxCount={10}
                className="mt-4"
                isCrop
            />

            <Input
                label={'Video'}
                value={videoUrl}
                onChange={(e) => setVideoUrl(e.target.value)}
                placeholder={'Youtube url girin'}
            />
        </div>
    )
}

export default FoodAddGallery
