import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getFoodComment, getFoodDetail } from '../../helper/api'
import { toast } from 'react-toastify'
import { Carousel, Image, Tooltip } from 'antd'
import label from '../../constant/label'
import timeList from '../../constant/timeList'
import { Button } from '../../components'

const FoodDetail = () => {
    const { id } = useParams()

    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [comments, setComments] = useState([])
    const [page, setPage] = useState(1)
    const [showCommentLoading, setShowCommentLoading] = useState(false)

    const getDetail = async () => {
        setLoading(true)
        const { status, result, message } = await getFoodDetail(id)
        if (status) {
            setData(result)
        } else {
            toast(message, {
                type: 'error'
            })
        }
        setLoading(false)
    }

    const handleGetComment = async () => {
        setShowCommentLoading(true)
        const { status, result } = await getFoodComment(id, page)
        if (status) {
            setComments([...comments, ...result])
            setPage(page + 1)
        }
        setShowCommentLoading(false)
    }

    useEffect(() => {
        getDetail()
    }, [])

    if (loading) return <div>Yükleniyor</div>
    if (!data) return <div>Hata</div>
    return (
        <div>
            <Carousel autoplay>
                {data.images.map((_) => (
                    <Image width={200} src={_.image} />
                ))}
            </Carousel>
            <div className="flex flex-col text-xl font-bold">
                <span className="flex">{data.name}</span>
                <span className="flex items-center">
                    <Tooltip title={label[data.user.country]}>
                        <Image
                            preview={false}
                            width={28}
                            src={require('../../assets/flag/' +
                                data.user.country +
                                '.png')}
                        />
                    </Tooltip>
                    <span className="ml-2 text-gray-600">
                        {data.user.username}
                    </span>
                </span>
            </div>
            <div className="flex flex-col mt-4 p-2 bg-gray-100">
                <span className="text-xl font-semibold">Genel Bilgiler</span>
                <span>
                    Pişirme Süresi:{' '}
                    {timeList.find((_) => _.value === data.cooking_time).label}
                </span>
                {data.preperation_time && (
                    <span>
                        Hazırlık Süresi:{' '}
                        {
                            timeList.find(
                                (_) => _.value === data.preperation_time
                            ).label
                        }
                    </span>
                )}
                <span>Kişi Sayısı: {data.person_number}</span>
                <span>Görüntülenme Sayısı: {data.views}</span>
                <span>Favori Sayısı: {data.favorite_count}</span>
                <span>Yayınlanma Tarihi: {data.created_at}</span>
            </div>
            {data.description && (
                <div className="flex flex-col mt-4 p-2 bg-gray-100">
                    <span className="text-xl font-semibold">Açıklama</span>
                    <span>{data.description}</span>
                </div>
            )}
            <div className="flex flex-col mt-4 p-2 bg-gray-100">
                <span className="text-xl font-semibold">Kategoriler</span>
                <span>{data.categories.map((_) => _.name).join(', ')}</span>
            </div>
            <div className="flex flex-col mt-4 p-2 bg-gray-100">
                <span className="text-xl font-semibold">Malzemeler</span>
                <span>
                    {data.materials.map(
                        (_) => `${_.value} ${_.unit.name} ${_.material.name}`
                    )}
                </span>
            </div>
            <div className="flex flex-col mt-4 p-2 bg-gray-100">
                <span className="text-xl font-semibold">Tarif</span>
                <span>·{data.recipes.map((_) => _.recipe)}</span>
            </div>
            {comments.map((_) => (
                <div className="bg-gray-200">
                    <span className="flex items-center">
                        <Tooltip title={label[_.user.country]}>
                            <Image
                                preview={false}
                                width={28}
                                src={require('../../assets/flag/' +
                                    _.user.country +
                                    '.png')}
                            />
                        </Tooltip>
                        <span className="ml-2 text-gray-600">
                            {_.user.username}
                        </span>
                    </span>
                    <div className="text-start">{_.content}</div>
                </div>
            ))}

            {data.comment_count > 0 &&
                page < Math.trunc(data.comment_count / 10) + 2 && (
                    <div className="mt-5">
                        <Button
                            onClick={handleGetComment}
                            loading={showCommentLoading}
                        >
                            Yorumları Göster (
                            {data.comment_count - comments.length})
                        </Button>
                    </div>
                )}
        </div>
    )
}

export default FoodDetail

/*
{data.map((_) => (
                <View key={uuid()} row aic mt={5}>
                    <View width={20}>
                        <FastImage
                            style={{
                                width: 12,
                                height: 12
                            }}
                            source={{ uri: _.material.image }}
                            resizeMode={FastImage.resizeMode.contain}
                        />
                    </View>

                    <Text flex medium>
                        {_.value} {_.unit.name} {_.material.name}
                    </Text>
                </View>
            ))}
 */
