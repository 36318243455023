import {
    BrowserRouter,
    Route,
    Routes,
    Navigate,
    Outlet
} from 'react-router-dom'
import Login from './pages/Login/Login'
import PrivateRoute from './utils/PrivateRoute'
import Dashboard from './pages/Home/Dashboard'
import CategoryAdd from './pages/Categories/CategoryAdd'
import MaterialAdd from './pages/Materials/MaterialAdd'
import MaterialEdit from './pages/Materials/MaterialEdit'
import CategoryEdit from './pages/Categories/CategoryEdit'
import Accounts from './pages/Home/Accounts'
import Materials from './pages/Materials/Materials'
import Categories from './pages/Categories/Categories'
import Units from './pages/Units/Units'
import UnitAdd from './pages/Units/UnitAdd'
import UnitEdit from './pages/Units/UnitEdit'
import Users from './pages/Users/Users'
import Foods from './pages/Foods/Foods'
import UserDetail from './pages/Users/UserDetail'
import UserEdit from './pages/Users/UserEdit'
import UserAdd from './pages/Users/UserAdd'
import Devices from './pages/Devices/Devices'
import FoodDetail from './pages/Foods/FoodDetail'
import FoodAdd from './pages/Foods/FoodAdd'
import DeviceDetail from './pages/Devices/DeviceDetail'

// TODO: TokenContext'i uygun bir vakitte kaldır

function Routers() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path={'/'} element={<Navigate to={'/login'} />} />
                <Route path="/login" element={<Login />} />

                <Route path="" element={<PrivateRoute />}>
                    <Route
                        index={true}
                        path="dashboard"
                        element={<Dashboard />}
                    />
                    <Route path="categories">
                        <Route index={true} element={<Categories />} />
                        <Route path="add" element={<CategoryAdd />} />
                        <Route path="edit/:id" element={<CategoryEdit />} />
                    </Route>
                    <Route path="materials">
                        <Route index={true} element={<Materials />} />
                        <Route path="add" element={<MaterialAdd />} />
                        <Route path="edit/:id" element={<MaterialEdit />} />
                    </Route>
                    <Route path="units">
                        <Route index={true} element={<Units />} />
                        <Route path="add" element={<UnitAdd />} />
                        <Route path="edit/:id" element={<UnitEdit />} />
                    </Route>
                    <Route path="users">
                        <Route index={true} element={<Users />} />
                        <Route path="detail/:id" element={<UserDetail />} />
                        <Route path="add" element={<UserAdd />} />
                        <Route path="edit/:id" element={<UserEdit />} />
                    </Route>
                    <Route path="foods">
                        <Route index={true} element={<Foods />} />
                        <Route path="detail/:id" element={<FoodDetail />} />
                        <Route path="add" element={<FoodAdd />} />
                    </Route>
                    <Route path="devices">
                        <Route index={true} element={<Devices />} />
                        <Route path="detail/:id" element={<DeviceDetail />} />
                    </Route>
                    <Route path="accounts" element={<Accounts />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default Routers
