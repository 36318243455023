import React from 'react';

const Accounts = () => {
    return (
        <div>
            Accounts
        </div>
    );
};

export default Accounts;
