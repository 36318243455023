import React from 'react';
import Card from "../../components/Card";

const Dashboard = () => {
    return (
        <div>
            Dashboard
            <div className="container mx-auto">
                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
                    <Card count={20} title={'Categories'}/>
                    <Card count={28} title={'Materials'}/>
                    <Card count={12} title={'Foods'}/>
                    <Card count={43} title={'Recipe'}/>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
