import React from 'react'
import {
    ApiOutlined,
    AppstoreOutlined,
    BookOutlined,
    FieldBinaryOutlined,
    ShoppingOutlined,
    TabletOutlined,
    UserOutlined
} from '@ant-design/icons'
import { Layout as AntdLayout, Menu, theme } from 'antd'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { removeStorage } from '../helper/localStorageHelpers'
import { ToastContainer } from 'react-toastify'

const { Content, Footer, Sider } = AntdLayout

const items = [
    {
        label: 'Kategoriler',
        key: 'categories',
        link: '/categories',
        icon: React.createElement(AppstoreOutlined)
    },
    {
        label: 'Malzemeler',
        key: 'materials',
        link: '/materials',
        icon: React.createElement(ShoppingOutlined)
    },
    {
        label: 'Birimler',
        key: 'units',
        link: '/units',
        icon: React.createElement(FieldBinaryOutlined)
    },
    {
        label: 'Tarifler',
        key: 'foods',
        link: '/foods',
        icon: React.createElement(BookOutlined)
    },
    {
        label: 'Şefler',
        key: 'users',
        link: '/users',
        icon: React.createElement(UserOutlined)
    },
    {
        label: 'Cihazlar',
        key: 'devices',
        link: '/devices',
        icon: React.createElement(TabletOutlined)
    },
    {
        label: 'Çıkış Yap',
        key: 'exit',
        link: '',
        icon: React.createElement(ApiOutlined)
    }
]

const Layout = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const {
        token: { colorBgContainer }
    } = theme.useToken()

    const handleExit = () => {
        removeStorage('accessToken')
        removeStorage('refreshToken')
        navigate('/login')
    }

    const handleItem = ({ item, key }) => {
        if (key === 'exit') {
            handleExit()
            return
        }
        navigate(key)
    }

    return (
        <AntdLayout hasSider>
            <Sider
                breakpoint="md"
                collapsible
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'sticky',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    zIndex: 1
                }}
            >
                <div
                    style={{
                        margin: 0,
                        padding: 16
                    }}
                >
                    <span
                        className="hidden text-white text-2xl font-bold md:flex items-center justify-center"
                        style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
                    >
                        RECIPLES
                    </span>
                </div>
                <div className="p-2">
                    <Menu
                        theme="dark"
                        mode="inline"
                        defaultSelectedKeys={[location.pathname.split('/')[1]]}
                        items={items}
                        onClick={handleItem}
                    />
                </div>
            </Sider>
            <AntdLayout className="site-layout">
                <Content className="m-2 sm:m-6">
                    <div
                        style={{
                            padding: 24,
                            textAlign: 'center',
                            background: colorBgContainer
                        }}
                    >
                        <Outlet />
                    </div>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center'
                    }}
                >
                    Reciples ©2023
                </Footer>
            </AntdLayout>
            <ToastContainer position="top-right" newestOnTop />
        </AntdLayout>
    )
}
export default Layout
