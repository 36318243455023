import React, { useState } from 'react'
import { DndContext } from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { Modal, Table } from 'antd'
import { Button, Select } from '../../components'
import valueList from '../../constant/valueList'
import { CSS } from '@dnd-kit/utilities'

const columns = [
    {
        title: 'Değer',
        dataIndex: 'value',
        render: (_) => <div>{_.label}</div>
    },
    {
        title: 'Birim',
        dataIndex: 'unit',
        render: (_) => <div>{_.label}</div>
    },
    {
        title: 'Malzeme',
        dataIndex: 'material',
        render: (_) => <div>{_.label}</div>
    }
]
const Row = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({
        id: props['data-row-key']
    })
    const style = {
        ...props.style,
        transform: CSS.Transform.toString(
            transform && {
                ...transform,
                scaleY: 1
            }
        ),
        transition,
        cursor: 'move',
        ...(isDragging
            ? {
                  position: 'relative',
                  zIndex: 9999
              }
            : {})
    }
    return (
        <tr
            {...props}
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
        />
    )
}

const FoodAddMaterial = ({
    materials,
    setMaterials,
    unitList,
    materialList,
    getMaterials
}) => {
    const [materialValue, setMaterialValue] = useState()
    const [materialUnit, setMaterialUnit] = useState()
    const [material, setMaterial] = useState()
    const [modal, setModal] = useState(false)

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setMaterials((prev) => {
                const activeIndex = prev.findIndex((i) => i.key === active.id)
                const overIndex = prev.findIndex((i) => i.key === over?.id)
                return arrayMove(prev, activeIndex, overIndex)
            })
        }
    }

    const handleAddMaterial = () => {
        setMaterials([
            ...materials,
            {
                value: materialValue,
                unit: materialUnit,
                material,
                key: materials.length
            }
        ])
        setMaterialValue(null)
        setMaterialUnit(null)
        setMaterial(null)
        setModal(false)
    }

    return (
        <>
            <div className="mt-4">
                <div className="text-xl font-semibold text-start">
                    Malzemeler*
                </div>
                {materials.length > 0 && (
                    <DndContext onDragEnd={onDragEnd}>
                        <SortableContext
                            items={materials.map((i) => i.key)}
                            strategy={verticalListSortingStrategy}
                        >
                            <Table
                                components={{
                                    body: {
                                        row: Row
                                    }
                                }}
                                rowKey="key"
                                columns={columns}
                                dataSource={materials}
                                pagination={false}
                            />
                        </SortableContext>
                    </DndContext>
                )}

                <Button
                    className="mt-4 bg-blue-500"
                    onClick={() => setModal(true)}
                >
                    Malzeme Ekle
                </Button>
            </div>
            <Modal
                title="Malzeme Ekle"
                centered
                open={modal}
                onCancel={() => setModal(false)}
                footer={[
                    <Button key="cancel" onClick={() => setModal(false)}>
                        İptal
                    </Button>,
                    <Button
                        key="link"
                        type="primary"
                        onClick={handleAddMaterial}
                    >
                        Kaydet
                    </Button>
                ]}
            >
                <div className="space-y-4">
                    <Select
                        label={'Değer'}
                        placeholder={'Değer seçin'}
                        options={valueList}
                        value={materialValue}
                        setValue={setMaterialValue}
                    />
                    <Select
                        label={'Birim'}
                        placeholder={'Birim seçin'}
                        options={unitList}
                        value={materialUnit}
                        setValue={setMaterialUnit}
                    />
                    <Select
                        label={'Malzeme'}
                        placeholder={'Malzeme seçin'}
                        options={materialList}
                        filterOption={false}
                        onSearch={(value) => getMaterials(value)}
                        value={material}
                        setValue={setMaterial}
                    />
                </div>
            </Modal>
        </>
    )
}

export default FoodAddMaterial
