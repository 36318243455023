import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Pagination, Table, Tag } from 'antd'
import { getDevice } from '../../helper/api'

const Devices = () => {
    const navigate = useNavigate()

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [count, setCount] = useState(null)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const [active, setActive] = useState('all')
    const [order, setOrder] = useState('id')
    const [sort, setSort] = useState('descend')

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: true,
            sortOrder: order === 'id' ? sort : null,
            showSorterTooltip: false
        },
        {
            title: 'Marka',
            dataIndex: 'brand',
            key: 'brand',
            sorter: true,
            sortOrder: order === 'brand' ? sort : null,
            showSorterTooltip: false
        },
        {
            title: 'Model',
            dataIndex: 'device_id',
            key: 'device_id',
            sorter: true,
            sortOrder: order === 'device_id' ? sort : null,
            showSorterTooltip: false
        },
        {
            title: 'Versiyon',
            dataIndex: 'build_number',
            key: 'build_number',
            sorter: true,
            sortOrder: order === 'build_number' ? sort : null,
            showSorterTooltip: false
        },
        {
            title: 'Oturum Sayısı',
            dataIndex: 'session_count',
            key: 'session_count',
            sorter: true,
            sortOrder: order === 'session_count' ? sort : null,
            showSorterTooltip: false
        },
        {
            title: 'Oluşturulma Tarihi',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: true,
            sortOrder: order === 'created_at' ? sort : null,
            showSorterTooltip: false
        },
        {
            title: 'Son Görülme Tarihi',
            dataIndex: 'updated_at',
            key: 'updated_at',
            sorter: true,
            sortOrder: order === 'updated_at' ? sort : null,
            showSorterTooltip: false
        },
        {
            title: 'Durum',
            dataIndex: 'active',
            key: 'active',
            render: (_) => (
                <Tag color={_ ? 'green' : 'red'} className="p-1">
                    {_ ? 'Aktif' : 'Pasif'}
                </Tag>
            ),
            sorter: true,
            sortOrder: order === 'active' ? sort : null,
            showSorterTooltip: false
        }
    ]

    const handleChange = (value) => {
        setActive(value)
        console.log(`selected ${value}`)
    }

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra)
        if (sorter) {
            if (sorter.columnKey === order) {
                setSort(sort === 'ascend' ? 'descend' : 'ascend')
                return
            }
            setOrder(sorter.columnKey)
        }
    }

    const getDeviceList = async () => {
        setLoading(true)
        const { status, count, result } = await getDevice(
            page,
            10,
            active,
            order,
            sort
        )
        if (status) {
            setData(result)
            setCount(count)
        }
        setLoading(false)
    }

    useEffect(() => {
        getDeviceList()
    }, [page, search, active, order, sort])

    return (
        <>
            <div className="flex flex-col mb-5">
                <h1 className="font-bold text-3xl text-left">
                    Cihazlar ({count})
                </h1>
            </div>
            <Table
                className="w-full"
                loading={loading}
                dataSource={data}
                columns={columns}
                pagination={false}
                onChange={onChange}
                footer={() => (
                    <div className="flex justify-center">
                        <Pagination
                            disabled={loading}
                            current={page}
                            onChange={(_) => setPage(_)}
                            total={count}
                            showSizeChanger={false}
                        />
                    </div>
                )}
                scroll={{ x: true }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            navigate('/devices/detail/' + record.id)
                        }
                    }
                }}
            />
        </>
    )
}

export default Devices
