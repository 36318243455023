import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Image, Input, Pagination, Table, Tag, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { getUser } from '../../helper/api'
import label from '../../constant/label'

const Users = () => {
    const navigate = useNavigate()

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [count, setCount] = useState(0)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const [active, setActive] = useState('all')
    const [order, setOrder] = useState('id')
    const [sort, setSort] = useState('descend')

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: true,
            sortOrder: order === 'id' ? sort : null,
            showSorterTooltip: false
        },
        {
            title: 'Resim',
            dataIndex: 'image',
            key: 'image',
            render: (_) => (
                <Tooltip title={label[_]}>
                    <Image preview={true} width={48} src={_} />
                </Tooltip>
            )
        },

        {
            title: 'Kullanıcı Adı',
            dataIndex: 'username',
            key: 'username',
            sorter: true,
            sortOrder: order === 'username' ? sort : null,
            showSorterTooltip: false,
            render: (_, record) => (
                <span className="flex items-center" onClick={null}>
                    <Tooltip title={label[record.country]}>
                        <Image
                            preview={false}
                            width={28}
                            src={require('../../assets/flag/' +
                                record.country +
                                '.png')}
                        />
                    </Tooltip>
                    <span className="ml-2">{_}</span>
                </span>
            )
        },
        {
            title: 'E-Posta',
            dataIndex: 'email',
            key: 'email',
            sorter: true,
            sortOrder: order === 'email' ? sort : null,
            showSorterTooltip: false
        },
        {
            title: 'Kayıt Tarihi',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: true,
            sortOrder: order === 'created_at' ? sort : null,
            showSorterTooltip: false
        },
        {
            title: 'Doğrulama',
            dataIndex: 'verification',
            key: 'verification',
            render: (_) => (
                <Tag color={_ ? 'green' : 'red'} className="p-1">
                    {_ ? 'Doğrulandı' : 'Doğrulanmadı'}
                </Tag>
            ),
            sorter: true,
            sortOrder: order === 'verification' ? sort : null,
            showSorterTooltip: false
        },
        {
            title: 'Durum',
            dataIndex: 'active',
            key: 'active',
            render: (_) => (
                <Tag color={_ ? 'green' : 'red'} className="p-1">
                    {_ ? 'Aktif' : 'Pasif'}
                </Tag>
            ),
            sorter: true,
            sortOrder: order === 'active' ? sort : null,
            showSorterTooltip: false
        }
    ]

    const handleChange = (value) => {
        setActive(value)
        console.log(`selected ${value}`)
    }

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra)
        if (sorter) {
            if (sorter.columnKey === order) {
                setSort(sort === 'ascend' ? 'descend' : 'ascend')
                return
            }
            setOrder(sorter.columnKey)
        }
    }

    const getUserList = async () => {
        setLoading(true)
        const { status, count, result } = await getUser(
            page,
            10,
            search,
            active,
            order,
            sort
        )
        if (status) {
            setData(result)
            setCount(count)
        }
        setLoading(false)
    }

    useEffect(() => {
        getUserList()
    }, [page, search, active, order, sort])

    return (
        <>
            <div className="flex flex-col mb-5">
                <h1 className="font-bold text-3xl text-left">
                    Şefler ({count})
                </h1>
                <div className="flex flex-col sm:flex-row mt-2 gap-4">
                    <Input
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Şef veya e-posta ara"
                        size={'large'}
                    />
                    <Button
                        type="default"
                        icon={<PlusOutlined />}
                        size={'large'}
                        //onClick={() => navigate('/foods/add')}
                    >
                        Kullanıcı Ekle
                    </Button>
                </div>
            </div>
            <Table
                className="w-full"
                loading={loading}
                dataSource={data}
                columns={columns}
                pagination={false}
                onChange={onChange}
                footer={() => (
                    <div className="flex justify-center">
                        <Pagination
                            disabled={loading}
                            current={page}
                            onChange={(_) => setPage(_)}
                            total={count}
                            showSizeChanger={false}
                        />
                    </div>
                )}
                scroll={{ x: true }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            navigate('/users/detail/' + record.id)
                        }
                    }
                }}
            />
        </>
    )
}

export default Users
