import React, { useEffect, useState } from 'react'
import { LanguageInput } from '../../components'
import { editAdminUnit, getUnitDetail } from '../../helper/api'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { formatLanguageCode } from '../../helper/language'

const UnitsEdit = () => {
    const { id } = useParams()

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [language, setLanguage] = useState({
        de: '',
        el: '',
        en: '',
        es: '',
        fr: '',
        hi: '',
        it: '',
        ja: '',
        ko: '',
        pl: '',
        pt: '',
        ru: '',
        tr: '',
        zh: ''
    })

    const disabled =
        language.de.trim().length === 0 ||
        language.el.trim().length === 0 ||
        language.en.trim().length === 0 ||
        language.es.trim().length === 0 ||
        language.fr.trim().length === 0 ||
        language.hi.trim().length === 0 ||
        language.it.trim().length === 0 ||
        language.ja.trim().length === 0 ||
        language.ko.trim().length === 0 ||
        language.pl.trim().length === 0 ||
        language.pt.trim().length === 0 ||
        language.ru.trim().length === 0 ||
        language.tr.trim().length === 0 ||
        language.zh.trim().length === 0

    const editCategory = async () => {
        setLoading(true)
        const credentials = {
            name: language.tr.trim(),
            ...formatLanguageCode(language)
        }
        const { status, message } = await editAdminUnit(id, credentials)
        if (status) {
            toast('Birim başarıyla güncellendi.', {
                type: 'success'
            })
            navigate(-1)
        } else {
            toast(message, {
                type: 'error'
            })
        }
        setLoading(false)
    }

    const getDetail = async () => {
        setLoading(true)
        const { status, result, message } = await getUnitDetail(id)
        if (status) {
            setLanguage(formatLanguageCode(result))
        } else {
            toast(message, {
                type: 'error'
            })
        }
        setLoading(false)
    }

    useEffect(() => {
        getDetail()
    }, [])

    return (
        <div>
            <h1 className="font-bold text-3xl text-left mb-5">Birim Düzenle</h1>
            <LanguageInput
                placeholder={'Birim'}
                loading={loading}
                setLoading={setLoading}
                language={language}
                setLanguage={setLanguage}
            />
            <Button
                block
                type="primary"
                icon={<UploadOutlined />}
                size={'large'}
                loading={loading}
                onClick={editCategory}
                className="mt-4 bg-blue-400"
                disabled={disabled}
            >
                Güncelle
            </Button>
        </div>
    )
}

export default UnitsEdit
