export default [
    { label: '30 Saniye', value: '0.5' },
    { label: '1 Dakika', value: '1' },
    { label: '1 Dakika 30 Saniye', value: '1.5' },
    { label: '2 Dakika', value: '2' },
    { label: '2 Dakika 30 Saniye', value: '2.5' },
    { label: '3 Dakika', value: '3' },
    { label: '3 Dakika 30 Saniye', value: '3.5' },
    { label: '4 Dakika', value: '4' },
    { label: '4 Dakika 30 Saniye', value: '4.5' },
    { label: '5 Dakika', value: '5' },
    { label: '5 Dakika 30 Saniye', value: '5.5' },
    { label: '6 Dakika', value: '6' },
    { label: '6 Dakika 30 Saniye', value: '6.5' },
    { label: '7 Dakika', value: '7' },
    { label: '7 Dakika 30 Saniye', value: '7.5' },
    { label: '8 Dakika', value: '8' },
    { label: '8 Dakika 30 Saniye', value: '8.5' },
    { label: '9 Dakika', value: '9' },
    { label: '9 Dakika 30 Saniye', value: '9.5' },
    { label: '10 Dakika', value: '10' },
    { label: '11 Dakika', value: '11' },
    { label: '12 Dakika', value: '12' },
    { label: '13 Dakika', value: '13' },
    { label: '14 Dakika', value: '14' },
    { label: '15 Dakika', value: '15' },
    { label: '20 Dakika', value: '20' },
    { label: '25 Dakika', value: '25' },
    { label: '30 Dakika', value: '30' },
    { label: '35 Dakika', value: '35' },
    { label: '40 Dakika', value: '40' },
    { label: '45 Dakika', value: '45' },
    { label: '50 Dakika', value: '50' },
    { label: '55 Dakika', value: '55' },
    { label: '1 Saat', value: '60' },
    { label: '1 Saat 10 Dakika', value: '70' },
    { label: '1 Saat 20 Dakika', value: '80' },
    { label: '1 Saat 30 Dakika', value: '90' },
    { label: '1 Saat 40 Dakika', value: '100' },
    { label: '1 Saat 50 Dakika', value: '110' },
    { label: '2 Saat', value: '120' },
    { label: '2 Saat 15 Dakika', value: '135' },
    { label: '2 Saat 30 Dakika', value: '150' },
    { label: '2 Saat 45 Dakika', value: '165' },
    { label: '3 Saat', value: '180' },
    { label: '3 Saat 15 Dakika', value: '195' },
    { label: '3 Saat 30 Dakika', value: '210' },
    { label: '3 Saat 45 Dakika', value: '225' },
    { label: '4 Saat', value: '240' },
    { label: '4 Saat 30 Dakika', value: '270' },
    { label: '5 Saat', value: '300' },
    { label: '5 Saat 30 Dakika', value: '330' },
    { label: '6 Saat', value: '360' },
    { label: '6 Saat 30 Dakika', value: '390' },
    { label: '7 Saat', value: '420' },
    { label: '7 Saat 30 Dakika', value: '450' },
    { label: '8 Saat', value: '480' },
    { label: '9 Saat', value: '540' },
    { label: '10 Saat', value: '600' },
    { label: '11 Saat', value: '660' },
    { label: '12 Saat', value: '720' },
    { label: '13 Saat', value: '780' },
    { label: '14 Saat', value: '840' },
    { label: '15 Saat', value: '900' },
    { label: '16 Saat', value: '960' },
    { label: '17 Saat', value: '1020' },
    { label: '18 Saat', value: '1080' },
    { label: '19 Saat', value: '1140' },
    { label: '20 Saat', value: '1200' },
    { label: '21 Saat', value: '1260' },
    { label: '22 Saat', value: '1320' },
    { label: '23 Saat', value: '1380' },
    { label: '1 Gün', value: '1440' },
    { label: '2 Gün', value: '2880' },
    { label: '3 Gün', value: '4320' },
    { label: '4 Gün', value: '5760' },
    { label: '5 Gün', value: '7200' },
    { label: '6 Gün', value: '8640' },
    { label: '1 Hafta', value: '10080' }
]
