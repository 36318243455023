import React from 'react'
import { Image, Tooltip } from 'antd'
import label from '../../constant/label'

const UserDetailInformation = ({ data }) => {
    return (
        <div>
            <Image width={200} src={data.image} />
            <div className="flex items-center justify-center">
                <Tooltip title={label[data.country]}>
                    <Image
                        preview={false}
                        width={34}
                        src={require('../../assets/flag/' +
                            data.country +
                            '.png')}
                    />
                </Tooltip>
                <span className="ml-2 text-gray-600 text-xl">
                    {data.username}
                </span>
            </div>
            <div>Oluşturulma Tarihi: {data.created_at}</div>
            <div>Güncellenme Tarihi: {data.updated_at}</div>
            <div>Dil: {data.language}</div>
            <div>Görüntülenen Ülke: {data.display_country}</div>
            <div>E-Posta: {data.email}</div>
            <div>Açıklama: {data.description}</div>
            <div>Tarif Sayısı: {data.foods_count}</div>
            <div>Takipçi Sayısı: {data.followers_count}</div>
            <div>Takip Sayısı: {data.followed_count}</div>
            <div>Tema: {data.theme}</div>
            <div>Tip: {data.type.name}</div>
            <div>
                Doğrulama: {data.verification ? 'Doğrulandı' : 'Doğrulanmadı'}
            </div>
            <div>Durum: {data.active ? 'Aktif' : 'Pasif'}</div>
            <div>Animasyon: {data.animation ? 'Aktif' : 'Pasif'}</div>
            <div>
                Bildirim: {data.notification_permission ? 'Aktif' : 'Pasif'}
            </div>
            <div>Mail: {data.mail_permission ? 'Aktif' : 'Pasif'}</div>
        </div>
    )
}

export default UserDetailInformation
