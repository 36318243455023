import React, { createContext, useContext, useState } from 'react'

const StatusContext = createContext()
export const useStatusContext = () => useContext(StatusContext)

const StatusContextProvider = ({ children }) => {
    const [selectKey, setSelectedKey] = useState()

    const value = {
        selectKey,
        setSelectedKey
    }

    return (
        <StatusContext.Provider value={value}>
            {children}
        </StatusContext.Provider>
    )
}
export default StatusContextProvider
