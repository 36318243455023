import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Input, Pagination, Table, Tag } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { getCategory } from '../../helper/api'

const Categories = () => {
    const navigate = useNavigate()

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [count, setCount] = useState(0)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const [active, setActive] = useState('all')
    const [order, setOrder] = useState('id')
    const [sort, setSort] = useState('descend')

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: true,
            sortOrder: order === 'id' ? sort : null,
            showSorterTooltip: false
        },
        {
            title: 'Kategori',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            sortOrder: order === 'name' ? sort : null,
            showSorterTooltip: false
        },
        {
            title: 'Oluşturma Tarihi',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: true,
            sortOrder: order === 'created_at' ? sort : null,
            showSorterTooltip: false
        },
        {
            title: 'Durum',
            dataIndex: 'active',
            key: 'active',
            render: (_) => (
                <Tag color={_ ? 'green' : 'red'} className="p-1">
                    {_ ? 'Aktif' : 'Pasif'}
                </Tag>
            ),
            sorter: true,
            sortOrder: order === 'active' ? sort : null,
            showSorterTooltip: false
        }
    ]

    const onChange = (pagination, filters, sorter, extra) => {
        if (sorter) {
            if (sorter.columnKey === order) {
                setSort(sort === 'ascend' ? 'descend' : 'ascend')
                return
            }
            setOrder(sorter.columnKey)
        }
    }

    const getCategoryList = async () => {
        setLoading(true)
        const { status, count, result } = await getCategory(
            page,
            10,
            search,
            active,
            order,
            sort
        )
        if (status) {
            setData(result)
            setCount(count)
        }
        setLoading(false)
    }

    useEffect(() => {
        getCategoryList()
    }, [page, search, active, order, sort])

    return (
        <>
            <div className="flex flex-col mb-5">
                <h1 className="font-bold text-3xl text-left">
                    Kategoriler ({count})
                </h1>
                <div className="flex flex-col sm:flex-row mt-2 gap-4">
                    <Input
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Kategori ara"
                        size={'large'}
                    />
                    <Button
                        type="default"
                        icon={<PlusOutlined />}
                        size={'large'}
                        onClick={() => navigate('/categories/add')}
                    >
                        Kategori Ekle
                    </Button>
                </div>
            </div>
            <Table
                className="w-full"
                loading={loading}
                dataSource={data}
                columns={columns}
                pagination={false}
                onChange={onChange}
                footer={() => (
                    <div className="flex justify-center">
                        <Pagination
                            disabled={loading}
                            current={page}
                            onChange={(_) => setPage(_)}
                            total={count}
                            showSizeChanger={false}
                        />
                    </div>
                )}
                scroll={{ x: true }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            navigate('/categories/edit/' + record.id)
                        }
                    }
                }}
            />
        </>
    )
}

export default Categories
