import React from 'react'
import { Button as AntdButton } from 'antd'

const Button = ({ children, ...props }) => {
    return (
        <AntdButton type="primary" className="bg-blue-500" {...props}>
            {children}
        </AntdButton>
    )
}

export default Button
