import React from 'react';

const Card = ({count, title}) => {
    return (
        <div className="m-5 mt-10 ">
            <div className="block rounded-lg shadow-lg bg-white max-w-sm text-center">
                <div className="p-2 md:p-4 lg:p-6">
                    <h5 className="text-gray-900 text-5xl font-medium mb-2">{count}</h5>
                    <p className="text-gray-700 text-base mb-4">
                        {title}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Card;
