import React from 'react'
import { Input as AntdInput } from 'antd'

const Input = ({ label, ...props }) => {
    return (
        <div className="flex flex-col items-start">
            {label && <span className="mb-1 font-semibold">{label}</span>}
            <AntdInput size={'large'} {...props} />
        </div>
    )
}

export default Input
