import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import '../../App.css'
import { getUserDetail, getUserFood } from '../../helper/api'
import { Loading } from '../../components'
import { Tabs } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import UserDetailInformation from './UserDetailInformation'

const items = [
    {
        label: 'Bilgiler',
        Icon: UserOutlined,
        Children: UserDetailInformation
    }
]

const UserDetail = () => {
    const { id } = useParams()
    const { state } = useLocation()

    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState([])
    const [userFood, setUserFood] = useState([])

    const [activeTab, setActiveTab] = useState('0')

    const getDetail = async () => {
        setLoading(true)
        const { status, result } = await getUserDetail(id)
        if (status) {
            setUser(result)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const getUserFoodList = async () => {
        setLoading(true)
        const { status, result } = await getUserFood(id, 1)
        if (status) {
            setUserFood(result)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        getDetail()
    }, [])

    if (loading) return <Loading />

    return (
        <div>
            <h1 className="font-bold text-3xl text-left">Şef Detay</h1>
            <Tabs
                size={'large'}
                activeKey={activeTab}
                onChange={(tabKey) => setActiveTab(tabKey)}
                items={items.map((_, i) => {
                    const id = String(i)
                    return {
                        label: (
                            <span>
                                <_.Icon />
                                {_.label}
                            </span>
                        ),
                        key: id,
                        children: <_.Children data={user} />
                    }
                })}
            />
        </div>
    )
}

export default UserDetail
