import { Navigate } from 'react-router-dom'

import Layout from '../layout/Layout'

const PrivateRoute = () => {
    const isAuthenticated = localStorage.getItem('accessToken')
    return isAuthenticated ? <Layout /> : <Navigate to="/login" replace />
}

export default PrivateRoute
