export default [
    {
        label: '1-2',
        value: 0
    },
    {
        label: '3-4',
        value: 1
    },
    {
        label: '5-6',
        value: 2
    },
    {
        label: '7-8',
        value: 3
    },
    {
        label: '9-10',
        value: 4
    },
    {
        label: '11-12',
        value: 5
    },
    {
        label: '13-14',
        value: 6
    },
    {
        label: '15-16',
        value: 7
    },
    {
        label: '17-18',
        value: 8
    },
    {
        label: '19-20',
        value: 9
    },
    {
        label: '21-24',
        value: 10
    },
    {
        label: '25-28',
        value: 11
    },
    {
        label: '29-34',
        value: 12
    }
]
