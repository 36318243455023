export const formatLanguageCode = (data) => {
    return {
        de: data.de.trim(),
        el: data.el.trim(),
        en: data.en.trim(),
        es: data.es.trim(),
        fr: data.fr.trim(),
        hi: data.hi.trim(),
        it: data.it.trim(),
        ja: data.ja.trim(),
        ko: data.ko.trim(),
        pl: data.pl.trim(),
        pt: data.pt.trim(),
        ru: data.ru.trim(),
        tr: data.tr.trim(),
        zh: data.zh.trim()
    }
}
