import React, {useState} from 'react';
import {TableHeader} from "../../components";
import Input from "../../components/Input";
import {addAdminUser} from "../../helper/api";
import {toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";

const UserAdd = () => {
    const navigate = useNavigate();
    const [loading, setLoading]= useState(false);
    const [email, setEmail]= useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [language, setLanguage] = useState("");
    const [country, setCountry] = useState("");
    const addUser =async () => {
        setLoading(true)
        const credentials ={
            email: email,
            username: username,
            password: password,
            language: language,
            country: country
        }
        const {status} = await addAdminUser(credentials)
        if(status){
            setLoading(false)
            toast("Kategori başarıyla eklendi.", {
                type: "success",
            });
            navigate(-1);
        } else {
            setLoading(false)
            toast.error("Lütfen tekrar deneyiniz!");
        }
    }

    return (
        <div>
            <TableHeader
                pageName={"Kullanıcı Ekle"}
                buttonTitle={"Kaydet"}
                onClick={addUser}
                loading={loading}
            />
            <div className="mt-10">
                <Input description={"Email"} value={email} onChange={(e)=>setEmail(e.target.value)} />
                <Input description={"Kullanıcı Adı"} value={username} onChange={(e)=>setUsername(e.target.value)} />
                <Input description={"Şifre"} value={password} onChange={(e)=>setPassword(e.target.value)} />
                <Input description={"Dil"} value={language} onChange={(e)=>setLanguage(e.target.value)} />
                <Input description={"Ülke"} value={country} onChange={(e)=>setCountry(e.target.value)} />
            </div>
            <ToastContainer position="bottom-right" newestOnTop />
        </div>
    );
};

export default UserAdd;
