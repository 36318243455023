export default [
    {
        label: 'beyzanurkepir;)',
        value: 3
    },
    {
        label: 'lezzettesevgi',
        value: 5
    }
]
