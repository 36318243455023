import { instance } from './instance'

export const login = async (credentials) => {
    try {
        const url = '/admin/authentication/login'
        const response = await instance.post(url, credentials)
        return response
    } catch (e) {
        return { status: false }
    }
}

export const wordTranslate = async (credentials) => {
    try {
        const url = '/admin/translate'
        return await instance.post(url, credentials)
    } catch (e) {
        return { status: false }
    }
}

export const addAdminCategory = async (credentials) => {
    try {
        const url = '/admin/category/add'
        return await instance.post(url, credentials)
    } catch (e) {
        console.log('error', e)
        return { status: false }
    }
}

export const getCategory = async (page, limit, name, active, order, sort) => {
    try {
        const url = `/admin/category/list?page=${page}&limit=${limit}&name=${name}&active=${active}&order=${order}&sort=${sort}`
        return await instance.get(url)
    } catch (e) {
        return { status: false }
    }
}

export const getCategoryDetail = async (id) => {
    try {
        const url = `/admin/category/detail/${id}`
        return await instance.get(url, id)
    } catch (e) {
        return { status: false }
    }
}

export const editAdminCategory = async (id, credentials) => {
    try {
        const url = `/admin/category/edit/${id}`
        return await instance.put(url, credentials)
    } catch (e) {
        return { status: false }
    }
}

export const deleteAdminCategory = async (id) => {
    try {
        const url = `/admin/category/active/${id}`
        return await instance.put(url)
    } catch (e) {
        return { status: false }
    }
}

export const addAdminMaterial = async (credentials) => {
    try {
        const url = '/admin/material/add'
        return await instance.post(url, credentials)
    } catch (e) {
        return { status: false }
    }
}

export const getMaterial = async (page, limit, name, active, order, sort) => {
    try {
        const url = `/admin/material/list?page=${page}&limit=${limit}&name=${name}&active=${active}&order=${order}&sort=${sort}`
        return await instance.get(url)
    } catch (e) {
        return { status: false }
    }
}

export const getMaterialDetail = async (id) => {
    try {
        const url = `/admin/material/detail/${id}`
        return await instance.get(url, id)
    } catch (e) {
        return { status: false }
    }
}

export const editAdminMaterial = async (id, credentials) => {
    try {
        const url = `/admin/material/edit/${id}`
        return await instance.put(url, credentials)
    } catch (e) {
        return { status: false }
    }
}

export const deleteAdminMaterial = async (id) => {
    try {
        const url = `/admin/material/active/${id}`
        return await instance.put(url)
    } catch (e) {
        return { status: false }
    }
}

export const addAdminUnit = async (credentials) => {
    try {
        const url = '/admin/unit/add'
        return await instance.post(url, credentials)
    } catch (e) {
        return { status: false }
    }
}

export const getUnit = async (page, limit, name, active, order, sort) => {
    try {
        const url = `/admin/unit/list?page=${page}&limit=${limit}&name=${name}&active=${active}&order=${order}&sort=${sort}`
        return await instance.get(url)
    } catch (e) {
        return { status: false }
    }
}

export const getUnitDetail = async (id) => {
    try {
        const url = `/admin/unit/detail/${id}`
        const response = await instance.get(url, id)
        return response
        return null
    } catch (e) {
        return { status: false }
    }
}

export const editAdminUnit = async (id, credentials) => {
    try {
        const url = `/admin/unit/edit/${id}`
        const response = await instance.put(url, credentials)
        return response
    } catch (e) {
        return { status: false }
    }
}

export const deleteAdminUnit = async (id) => {
    try {
        const url = `/admin/unit/active/${id}`
        const response = await instance.put(url)
        return response
    } catch (e) {
        return { status: false }
    }
}

export const getUser = async (page, limit, search, active, order, sort) => {
    try {
        const url = `/admin/user/list?page=${page}&limit=${limit}&search=${search}&active=${active}&order=${order}&sort=${sort}`
        return await instance.get(url)
    } catch (e) {
        return { status: false }
    }
}

export const getUserDetail = async (id) => {
    try {
        const url = `/admin/user/detail/${id}`
        return await instance.get(url, id)
    } catch (e) {
        return { status: false }
    }
}

export const addAdminUser = async (credentials) => {
    try {
        const url = '/admin/user/add'
        return await instance.post(url, credentials)
    } catch (e) {
        return { status: false }
    }
}

export const getUserFood = async (id, page) => {
    try {
        const url = `/admin/user/foods/${id}?page=${page}`
        const response = await instance.get(url, id)
        return response
        return null
    } catch (e) {
        return { status: false }
    }
}

export const getFood = async (page, limit, search, active, order, sort) => {
    try {
        const url = `/admin/food/list?page=${page}&limit=${limit}&food_name=${search}&active=${active}&order=${order}&sort=${sort}`
        return await instance.get(url)
    } catch (e) {
        return { status: false }
    }
}

export const getFoodDetail = async (id) => {
    try {
        const url = `/admin/food/detail/${id}`
        return await instance.get(url)
    } catch (e) {
        return { status: false }
    }
}
export const getFoodComment = async (foodId, page) => {
    try {
        const url = `/food/comment/list/${foodId}?page=${page}`
        return await instance.get(url)
    } catch (e) {
        return { status: false }
    }
}

export const getDevice = async (page, limit, active, order, sort) => {
    try {
        const url = `/admin/device/list?page=${page}&limit=${limit}&active=${active}&order=${order}&sort=${sort}`
        return await instance.get(url)
    } catch (e) {
        return { status: false }
    }
}

export const addFood = async (credentials) => {
    try {
        const url = '/food/add'
        return await instance.post(url, credentials)
    } catch (e) {
        return { status: false }
    }
}

export const getDeviceDetail = async (id) => {
    try {
        const url = `/admin/device/detail/${id}`
        return await instance.get(url)
    } catch (e) {
        return { status: false }
    }
}
