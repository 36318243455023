import React from 'react'
import { Input, Select } from '../../components'
import timeList from '../../constant/timeList'
import personNumberList from '../../constant/personNumberList'
import publisherList from '../../constant/publisherList'

const FoodAddGeneralInformation = ({
    name,
    setName,
    categoryList,
    categories,
    setCategories,
    cookingTime,
    setCookingTime,
    preparationTime,
    setPreparationTime,
    personNumber,
    setPersonNumber,
    description,
    setDescription,
    publisherUser,
    setPublisherUser
}) => {
    return (
        <div className="space-y-4">
            <Select
                label={'Paylaşan Kişi*'}
                placeholder={'Paylaşan kişi seçin'}
                options={publisherList}
                value={publisherUser}
                setValue={setPublisherUser}
                allowClear={false}
            />
            <Input
                label={'Tarif Adı*'}
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={'Tarif adı girin'}
            />
            <Select
                mode={'multiple'}
                label={'Kategori*'}
                placeholder={'Kategori seçin'}
                options={categoryList}
                value={categories}
                setValue={setCategories}
            />
            <Select
                label={'Pişirme Süresi*'}
                placeholder={'Pişirme süresi seçin'}
                options={timeList}
                value={cookingTime}
                setValue={setCookingTime}
            />
            <Select
                label={'Hazırlık Süresi'}
                placeholder={'Hazırlık süresi seçin'}
                options={timeList}
                value={preparationTime}
                setValue={setPreparationTime}
            />
            <Select
                label={'Kişi Sayısı*'}
                placeholder={'Kişi sayısı seçin'}
                options={personNumberList}
                value={personNumber}
                setValue={setPersonNumber}
            />
            <Input
                label={'Açıklama'}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder={'Açıklama girin'}
            />
        </div>
    )
}

export default FoodAddGeneralInformation
