import axios from 'axios'
import { getStorage, setStorage } from './localStorageHelpers'

const date = new Date()

export const instance = axios.create({
    baseURL: process.env.REACT_APP_URL,
    timeout: 30000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Timezone-Offset': -date.getTimezoneOffset()
    }
})

const request = async (config) => {
    const accessToken = getStorage('accessToken')
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
}

const response = (response) => {
    return Promise.resolve(response.data)
}

const error = async (error) => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        try {
            const refreshToken = await getStorage('refreshToken')
            const credentials = {
                refresh_token: refreshToken
            }
            const { status, result } = await instance.post(
                '/authentication/refresh_token',
                credentials
            )
            if (status) {
                await setStorage('accessToken', result.access_token)
                await setStorage('refreshToken', result.refresh_token)
            }
            originalRequest.headers.Authorization = `Bearer ${result.access_token}`
            return instance(originalRequest)
        } catch (e) {
            console.log('err', e)
        }
    }
    return Promise.reject(error)
}

instance.interceptors.request.use(request, error)
instance.interceptors.response.use(response, error)
