import React, { useState } from 'react'
import { LanguageInput, UploadImage } from '../../components'
import { addAdminMaterial } from '../../helper/api'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { getBase64 } from '../../helper/image'
import { formatLanguageCode } from '../../helper/language'

const MaterialAdd = () => {
    const navigate = useNavigate()
    const [language, setLanguage] = useState({
        tr: '',
        en: '',
        fr: '',
        es: '',
        it: '',
        ru: '',
        hi: '',
        pt: '',
        de: '',
        el: '',
        pl: '',
        zh: '',
        ja: '',
        ko: ''
    })

    const [loading, setLoading] = useState(false)
    const [imageList, setImageList] = useState([])

    const disabled =
        language.de.trim().length === 0 ||
        language.el.trim().length === 0 ||
        language.en.trim().length === 0 ||
        language.es.trim().length === 0 ||
        language.fr.trim().length === 0 ||
        language.hi.trim().length === 0 ||
        language.it.trim().length === 0 ||
        language.ja.trim().length === 0 ||
        language.ko.trim().length === 0 ||
        language.pl.trim().length === 0 ||
        language.pt.trim().length === 0 ||
        language.ru.trim().length === 0 ||
        language.tr.trim().length === 0 ||
        language.zh.trim().length === 0 ||
        imageList.length === 0

    const addMaterial = async () => {
        const b64 = await getBase64(imageList[0].originFileObj)
        const credentials = {
            name: language.tr.trim(),
            image: b64.split(',')[1],
            ...formatLanguageCode(language)
        }
        const { status, message } = await addAdminMaterial(credentials)
        if (status) {
            toast('Malzeme başarıyla eklendi.', {
                type: 'success'
            })
            navigate(-1)
        } else {
            toast(message, {
                type: 'error'
            })
        }
    }

    return (
        <div className="">
            <h1 className="font-bold text-3xl text-left mb-5">Malzeme Ekle</h1>
            <UploadImage imageList={imageList} setImageList={setImageList} />
            <LanguageInput
                placeholder={'Malzeme'}
                language={language}
                setLanguage={setLanguage}
                loading={loading}
                setLoading={setLoading}
            />
            <Button
                block
                type="primary"
                icon={<PlusOutlined />}
                size={'large'}
                loading={loading}
                onClick={addMaterial}
                className="mt-4 bg-blue-400"
                disabled={disabled}
            >
                Ekle
            </Button>
        </div>
    )
}

export default MaterialAdd
