import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { addFood, getCategory, getUnit } from '../../helper/api'
import { Button } from '../../components'
import FoodAddGeneralInformation from './FoodAddGeneralInformation'
import FoodAddMaterial from './FoodAddMaterial'
import FoodAddRecipe from './FoodAddRecipe'
import FoodAddGallery from './FoodAddGallery'
import { toast } from 'react-toastify'
import { youTubeUrlToId } from '../../helper/global'
import publisherList from '../../constant/publisherList'

const FoodAdd = () => {
    const navigate = useNavigate()

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [videoUrl, setVideoUrl] = useState('')
    const [categories, setCategories] = useState([])
    const [materials, setMaterials] = useState([])
    const [recipes, setRecipes] = useState([])
    const [images, setImages] = useState([])
    const [cookingTime, setCookingTime] = useState()
    const [preparationTime, setPreparationTime] = useState()
    const [personNumber, setPersonNumber] = useState()
    const [publisherUser, setPublisherUser] = useState(publisherList[1])

    const [loading, setLoading] = useState(false)

    const [categoryList, setCategoryList] = useState([])
    const [unitList, setUnitList] = useState([])
    const [materialList, setMaterialList] = useState([])

    const checkAvailable = () => {
        if (categories.length === 0) {
            toast('Kategori seç.', { type: 'error' })
            return false
        }
        if (name.trim().length === 0) {
            toast('Tarif adı boş.', { type: 'error' })
            return false
        }
        if (!cookingTime) {
            toast('Pişirme süresi seç.', { type: 'error' })
            return false
        }
        if (!personNumber) {
            toast('Kişi sayısı seç.', { type: 'error' })
            return false
        }
        if (recipes.length === 0) {
            toast('Tarif adımı gir.', { type: 'error' })
            return false
        }
        if (materials.length === 0) {
            toast('Malzeme seç.', { type: 'error' })
            return false
        }
        if (images.length === 0) {
            toast('Resim ekle.', { type: 'error' })
            return false
        }
        if (videoUrl.trim().length > 0 && !youTubeUrlToId(videoUrl.trim())) {
            toast('Geçersiz youtube linki.', { type: 'error' })
            return false
        }
        return true
    }

    const handlePublish = async () => {
        if (!checkAvailable()) {
            return
        }
        toast('Yayınlanıyor..', { type: 'info' })
        setLoading(true)
        const credentials = {
            categories_id: categories.map((_) => _.value),
            name,
            cooking_time: cookingTime.value,
            preparation_time: preparationTime ? preparationTime.value : null,
            person_number: personNumber.label,
            video_url: videoUrl.trim(),
            recipes: recipes.map((_, i) => {
                return { recipe: _.content, row_number: i }
            }),
            description,
            materials: materials.map((_, i) => {
                return {
                    value: _.value.value,
                    unit_id: _.unit.value,
                    material_id: _.material.value,
                    row_number: i
                }
            }),

            images: images.map((_, i) => {
                return {
                    selected: i === 0,
                    row_number: i,
                    image: _.base64.split(',')[1]
                }
            })
        }

        const { status, message } = await addFood(credentials)
        if (status) {
            navigate(-1)
            toast('Eklendi..', { type: 'success' })
        } else {
            toast(message, {
                type: 'error'
            })
        }
        setLoading(false)
    }

    const getCategories = async (search) => {
        const categories = await getCategory(
            1,
            25,
            search,
            'active',
            'name',
            'ascend'
        )
        if (categories.status) {
            setCategoryList(
                categories.result.map((_) => {
                    return { label: _.name, value: _.id }
                })
            )
        }
    }

    const getUnits = async (search) => {
        const units = await getUnit(1, 25, search, 'active', 'name', 'ascend')
        if (units.status) {
            setUnitList(
                units.result.map((_) => {
                    return { label: _.name, value: _.id }
                })
            )
        }
    }

    const getMaterials = async (search) => {
        const materials = await getUnit(
            1,
            5,
            search,
            'active',
            'name',
            'ascend'
        )
        if (materials.status) {
            setMaterialList(
                materials.result.map((_) => {
                    return { label: _.name, value: _.id }
                })
            )
        }
    }

    useEffect(() => {
        getCategories('')
        getUnits('')
        getMaterials('')
    }, [])

    return (
        <>
            <div>
                <h1 className="font-bold text-3xl text-left mb-5">
                    Tarif Ekle
                </h1>

                <FoodAddGeneralInformation
                    name={name}
                    setName={setName}
                    categoryList={categoryList}
                    categories={categories}
                    setCategories={setCategories}
                    cookingTime={cookingTime}
                    setCookingTime={setCookingTime}
                    preparationTime={preparationTime}
                    setPreparationTime={setPreparationTime}
                    personNumber={personNumber}
                    setPersonNumber={setPersonNumber}
                    description={description}
                    setDescription={setDescription}
                    publisherUser={publisherUser}
                    setPublisherUser={setPublisherUser}
                />

                <FoodAddMaterial
                    getMaterials={getMaterials}
                    materials={materials}
                    materialList={materialList}
                    setMaterials={setMaterials}
                    unitList={unitList}
                />

                <FoodAddRecipe recipes={recipes} setRecipes={setRecipes} />

                <FoodAddGallery
                    images={images}
                    setImages={setImages}
                    videoUrl={videoUrl}
                    setVideoUrl={setVideoUrl}
                />
                <Button
                    block
                    className="mt-8 bg-blue-500 h-12 font-bold"
                    onClick={handlePublish}
                >
                    Yayınla
                </Button>
            </div>
        </>
    )
}

export default FoodAdd
